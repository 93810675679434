






























































import { Component, Ref, Vue } from "vue-property-decorator";
import {
  AttachmentHostType,
  AuditFlowScope, CustomFormType,
  FundDto,
  FundMiniDto,
  FundProjectApplyDto,
  FundProjectDto,
} from "@/api/appService";
import api from "@/api";
import ExportWord from "@/components/DownloadWord/index.vue";
import AttachmentsView from "@/components/AttachmentsView/index.vue";

import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";
import CustomFormDetailView from "@/components/CustomForm/FormDetail/index.vue";

@Component({
  name: "fundProjectApplyDetail",
  components: {
    ExportWord,
    AttachmentsView,
    AuditBlock,
    CustomFormDetailView
  },
})
export default class FundProjectDetail extends Vue {

  dataId?: number;
  detail: FundProjectApplyDto = {};
  loadingProfile = true;

  get hostType() {
    return CustomFormType.FundProject;
  }

  created() {
    this.dataId = Number(this.$route.params.id);
  }

  getDetail() {
    return new Promise<FundProjectApplyDto>((resolve, reject) => {
      if (!this.loadingProfile) {
        resolve(this.detail);
      } else {
        api.fundProjectApply.get({id: this.dataId}).then((res) => {
          this.detail = res;
          this.loadingProfile = false;
          resolve(res);
        });
      }
    });
  }

  async fetchDetail() {
    api.fundProjectApply.get({ id: this.dataId }).then((res) => {
      this.detail = { ...res };
    });
  }

  get auditApi(){
    return {
      canAudit: api.fundProjectApply.canAudit,
      userAudit: api.fundProjectApply.audit
    } as AuditApi
  }

}
